.reset-view {
  display: inline-block;
  justify-content: center;
  width: 100%;
  text-align: center;
  vertical-align: middle;
}

.reset-view .randomize-button {
  width: 50%;
  margin-top: 60px;
  vertical-align: middle;
  background-color: teal;
  border-color: teal;
  height: 60px;
  font-size: 20px;
  justify-content: center;
}

.randomize-setup-button {
  background-color: teal;
  border-color: teal;
  min-height: 60px;
  font-size: 20px;
  justify-content: center;
}

.randomize-button-container {
  justify-content: center;
  padding-top: 50px;
}

.checkbox-selector {
  display: inline-block;
  margin-inline: 30px;
  width: 50%;
  align-items: center;
  margin-top: 10px;
}

.expandable-options {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 14px;
  justify-content: center;
}

.game-map-selector {
  justify-content: center;
}

.checkbox {
  margin-inline: 8px;
  align-self: center;
  justify-content: center;
}

.radio-header {
  font-size: 24px;
  margin-bottom: 10px;
  justify-content: center;
}

.radio-selector {
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-button {
  margin-right: 8px;
}

.game-component-selector {
  justify-content: center;
  margin-top: 30px;
}

.number-players-selector {
  margin-top: 10px;
  font-size: 18px;
  justify-content: center;
  margin-bottom: 10px;
}

.game-type-selector {
  margin-top: 40px;
}

.map-type-selector {
  margin-top: 40px;
}

.filter-option {
  margin-top: 20px;
}
