.select-clear-buttons {
  display: flex;
  padding-bottom: 20px;
}

.select-clear-buttons > * {
  margin-right: 20px;
}

.select-all-button {
  background-color: teal;
  border-color: teal;
}

.clear-all-button {
  background-color: white;
  border-color: teal;
  color: teal;
}

.cancel-button {
  background-color: grey;
  border-color: grey;
}

.modal-checkbox {
  align-items: center;
}

.modal-pic {
  padding-right: 10px;
  align-self: center;
}

.modal-label {
  align-self: center;
}

.filter-options-modal {
  font-family: "Josefin Sans";
  width: 100%;
}

.filter-options-button {
  color: teal;
  background: rgba(0, 128, 128, 0.2);
  border-color: teal;
  border-width: 2px;
  min-height: 50px;
  margin-bottom: 10px;
  font-size: 18px;
}

.nothing-selected-warning {
  padding-top: 5px;
  color: red;
  font-style: italic;
}

.modal-header {
  display: inline-block;
}

.modal-checkbox-and-label {
  padding-block: 5px;
  align-items: center;
}

.modal-body {
  margin: 10px;
}
