.spirits-container {
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  justify-content: center;
}

.spirits-array {
  justify-content: center;
}

.spirit {
  font-weight: normal;
  font-size: 20px;
  justify-content: center;
  padding-inline: 50px;
  padding-top: 25px;
}

.spirit-pic {
  padding-bottom: 10px;
  justify-content: center;
}

.spirit-name {
  justify-content: center;
  display: flex;
}

.spirit-header {
  font-size: larger;
  padding-bottom: 15px;
}
