.error-stripe {
  border-color: darkred;
  border-width: medium;
  background-color: #ffcccb;
  color: darkred;
  font-size: 16px;
  width: 100%;
  justify-content: center;
  margin: 0px;
}

.error-stripe-col {
  padding: 0px;
}
