.component-checkboxes {
  justify-content: center;
}

.game-component-pic {
  padding-bottom: 10px;
  padding-top: 10px;
  justify-content: center;
}

.game-component-options {
  justify-content: center;
}

.game-component-option {
  justify-content: center;
}

.game-component-label {
  text-align: center;
}

.component-checkbox-and-label {
  justify-content: center;
  padding-top: 15px;
}

.pic-image {
  max-width: 100px;
}
