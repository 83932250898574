.adv-l0-flag {
  opacity: 0.25;
  width: 100px;
}

.adv-flag {
  width: 100%;
  min-width: 30px;
  max-width: 80px;
}

.adv-flag-wrapper {
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.adv-flag-array {
  justify-content: center;
  align-items: center;
}
