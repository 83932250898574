.map-checkboxes {
  justify-content: center;
}

.checkbox-and-label {
  justify-content: center;
  align-items: center;
}

.checkbox {
  display: flex;
}

.map-type-options {
  justify-content: center;
}
