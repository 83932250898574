.try-again-button {
  width: 40%;
  min-width: 200px;
  margin-top: 30px;
  vertical-align: middle;
  background-color: teal;
  border-color: teal;
}

.reset-button {
  width: 15%;
  min-width: 150px;
  margin-top: 15px;
  vertical-align: middle;
  background-color: grey;
  border-color: grey;
}

.bottom-row-container {
  justify-content: center;
}

.bottom-row-container .bottom-row-element {
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  font-size: 28px;
  justify-content: center;
  padding-top: 25px;
}

.bottom-row-container .bottom-row-element .name {
  font-weight: normal;
  font-size: 20px;
  padding-top: 10px;
  justify-content: center;
}

.no-name {
  padding-top: 30px;
  font-weight: normal;
  font-size: 20px;
}

.button-container {
  display: flex;
  justify-content: center;
}
